import React from 'react'
import { Card, Box } from './primitives'
import Text from './core/Text'
import Heading from './core/Heading'
import Divider from './core/Divider'
import BlockLink from './core/BlockLink'

export default ({ post, ...props }) => {
  return (
    <Card height={[380, 400]} {...props} display='flex' flexDirection='column'>
      <BlockLink to={`/blog/${post.slug}`}>
        <Card
          py={100}
          color='white'
          bg='muted'
          mb={3}
          css={{
            backgroundImage: `url(${post.feature_image})`,
            backgroundSize: 'cover'
          }}
        />
      </BlockLink>
      <Text fontSize={1} mb={3}>
        {post.primary_tag.name}
      </Text>
      <BlockLink to={`/blog/${post.slug}`}>
        <Heading fontWeight='heavy' mb={2}>
          {post.title}
        </Heading>
        <Text>{post.excerpt}</Text>
      </BlockLink>
      <Box mt='auto'>
        {/* <Text mb={3}>{post.published_at}</Text> */}
        {/* <Text mb={3}>{post.primary_author.name}</Text> */}
        <Divider />
      </Box>
    </Card>
  )
}
