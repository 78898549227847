import React from 'react'
import { Card } from 'components/primitives'
import Link from './core/Link'
import { useStaticQuery, graphql } from 'gatsby'

export default props => {
  const {
    allGhostTag: { edges }
  } = useStaticQuery(graphql`
    {
      allGhostTag {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)

  const tags = edges.map(e => e.node)

  return (
    <Card
      bg='lightGray'
      py={2}
      {...props}
      display='flex'
      justifyContent='center'
      borderBottom='1px solid'
      borderColor='muted'
    >
      {tags.map((tag, index) => (
        <Link
          key={tag.slug}
          fontWeight='bold'
          color='lightText'
          mr={tags.length - 1 !== index ? 4 : 0}
          to={`/blog/${tag.slug}`}
        >
          {tag.name}
        </Link>
      ))}
    </Card>
  )
}
