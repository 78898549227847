import styled from '@emotion/styled'
import { Link as RouterLink } from 'gatsby'
import Text from './Text'

// Extends Text. Used to wrap block-level content.

const BlockLink = styled(Text)({
  cursor: 'pointer',
  textDecoration: 'none'
})

BlockLink.defaultProps = {
  ...Text.defaultProps,
  as: RouterLink,
  display: 'block',
  color: 'inherit'
}

export default BlockLink
