import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import BlogNav from 'components/blog-nav'
import PostCard from 'components/post-card'
import { Row, Column, container } from 'components/core/Layout'
import { Box } from 'components/primitives'
import Heading from 'components/core/Heading'

export default ({
  data: {
    allGhostPost: { edges }
  },
  pageContext,
  ...props
}) => {
  return (
    <Layout>
      <Head title={pageContext.name} />
      <BlogNav />
      <Box pt={5} {...container}>
        <Heading fontWeight='heavy' fontSize={5} mb={4}>
          {pageContext.name}
        </Heading>
        <Row>
          {edges.map(({ node }) => (
            <Column width={[1, 1 / 2, null, 1 / 3]} key={node.uuid}>
              <PostCard post={node} mb={5} />
            </Column>
          ))}
        </Row>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BlogTags($slug: String!) {
    allGhostPost(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          title
          slug
          uuid
          feature_image
          featured
          excerpt
          published_at
          primary_tag {
            slug
            name
          }
          primary_author {
            slug
            name
          }
        }
      }
    }
  }
`
